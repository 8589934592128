import {
    Box,
    Button,
    ButtonGroup,
    ButtonProps,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    SimpleGrid,
    StackDivider,
    Text,
    TextProps,
    Tooltip,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import { ReportingViewBaseConfig } from '../../../base';
import { AiOutlineEdit, AiOutlineInfoCircle, AiOutlinePlus } from 'react-icons/ai';
import { ReportInstanceDetailViewProps } from './reportDetailProps';
import { ReportChapterItemProps } from '../../../view/chapter';
import pluralize from 'pluralize';

export function createReportDetailView(
    config: ReportingViewBaseConfig
): React.FC<ReportInstanceDetailViewProps> {
    const {
        UI: {
            Application: { Link: UILink },
        },
        Layout: {
            Centered: {
                Component: {
                    Root: LayoutRoot,
                    Header: LayoutHeader,
                    Content: LayoutContent,
                    Body: LayoutBody,
                },
            },
        },
        router: {
            report: {
                Component: { Link: HashLink },
                useNavigate,
            },
        },
    } = config;

    const colors = {
        // accent: VAROS_YELLOW[50],
        // accentLight: VAROS_YELLOW[50],
        accent: 'whiteAlpha.500',
        accentLight: 'whiteAlpha.200',
    } satisfies Record<string, TextProps['color']>;

    const Card: React.FC<ReportChapterItemProps> = (props) => {
        return (
            <VStack align="start" w="full" spacing={4}>
                <VStack align="start" spacing={2}>
                    <VStack align="start" spacing={1}>
                        <HStack w="full" justify="space-between" align="start">
                            <Text
                                fontWeight="bold"
                                color={colors.accent}
                                fontSize="sm"
                                textTransform="uppercase"
                                letterSpacing="wider"
                            >
                                {props.caption}
                            </Text>
                            <Tooltip
                                placement="top"
                                openDelay={300}
                                px={2}
                                py={1}
                                bg="gray.50"
                                color="gray.900"
                                shouldWrapChildren={true}
                                label={`Last edited 4 hours ago by ${props.author.label}`}
                            >
                                <Text
                                    userSelect="none"
                                    fontSize="sm"
                                    fontWeight="medium"
                                    color="whiteAlpha.500"
                                >
                                    4h ago
                                </Text>
                            </Tooltip>
                        </HStack>
                        <HStack w="full" justify="space-between" align="start">
                            <HashLink to={props.id} style={{ textDecoration: 'none' }}>
                                <Heading
                                    color="whiteAlpha.800"
                                    fontWeight="semibold"
                                    fontSize="lg"
                                    cursor="pointer"
                                    _hover={{ textDecor: 'underline' }}
                                    noOfLines={1}
                                >
                                    {props.title}
                                </Heading>
                            </HashLink>
                        </HStack>
                    </VStack>
                    <Text color="whiteAlpha.600" fontSize="md" noOfLines={2}>
                        {props.description}
                    </Text>
                </VStack>
                <HStack spacing={2} flexShrink={0} w="full" justify="space-between">
                    <HashLink
                        to={props.id}
                        as={Button}
                        // w="full"
                        variant="outline"
                        colorScheme="blue"
                        // borderColor="whiteAlpha.200"
                        bg="none"
                        // rightIcon={<Icon fontSize="2xl" as={BsArrowRightShort} />}
                        borderRadius="full"
                        // size="sm"
                        // _hover={{ bg: 'whiteAlpha.50' }}
                    >
                        Read more
                    </HashLink>
                </HStack>
            </VStack>
        );
    };
    return (props) => (
        <LayoutRoot>
            <LayoutContent>
                <LayoutHeader>
                    <HStack w="full" justify="space-between" align="start">
                        <HStack w="full">
                            <VStack align="start" spacing={2}>
                                <VStack align="start" spacing={1}>
                                    <HStack fontSize="md" fontWeight="medium">
                                        <UILink to="../">
                                            <Text
                                                color="blue.300"
                                                _hover={{ color: 'blue.400' }}
                                                cursor="pointer"
                                            >
                                                Reports
                                            </Text>
                                        </UILink>
                                    </HStack>
                                    <Heading
                                        color="whiteAlpha.900"
                                        fontSize="2xl"
                                        fontWeight="semibold"
                                    >
                                        {props.item.item.title}
                                    </Heading>
                                </VStack>
                                <HStack
                                    spacing={2}
                                    color="whiteAlpha.500"
                                    divider={<Box border="none">&#x2022;</Box>}
                                >
                                    {props.item.item.keywords.map((keyword) => (
                                        <HStack key={keyword.id} spacing={2}>
                                            {keyword.Icon && <Icon as={keyword.Icon} />}
                                            <Text>{keyword.label}</Text>
                                        </HStack>
                                    ))}
                                </HStack>
                            </VStack>
                        </HStack>
                        <HStack spacing={3}>
                            <IconButton
                                variant="outline"
                                borderRadius="full"
                                borderColor="whiteAlpha.300"
                                aria-label="edit"
                                icon={<Icon as={AiOutlineEdit}></Icon>}
                                bg="none"
                                _hover={{ bg: 'whiteAlpha.50' }}
                            />
                        </HStack>
                    </HStack>
                </LayoutHeader>
                <LayoutBody>
                    <VStack align="start" w="full" spacing={12}>
                        <SimpleGrid
                            columns={2}
                            gap={6}
                            w="full"
                            templateColumns="min-content 1fr"
                        >
                            <Box maxH={16} py={1} h="full">
                                <Box
                                    bg="whiteAlpha.200"
                                    w={1.5}
                                    h="full"
                                    borderRadius="full"
                                />
                            </Box>
                            <Text fontSize="lg" color="whiteAlpha.700">
                                {props.item.summary.description}
                            </Text>
                        </SimpleGrid>
                        <Grid
                            w="full"
                            gap={6}
                            templateColumns={{
                                base: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                        >
                            {props.item.chapters.map((chapter) => (
                                <GridItem
                                    key={chapter.id}
                                    // bg="whiteAlpha.50"
                                    borderRadius="lg"
                                    borderWidth={2}
                                    borderColor="whiteAlpha.200"
                                    p={6}
                                >
                                    <Card {...chapter} />
                                </GridItem>
                            ))}
                            {props.item.chapters.length % 2 === 1 && (
                                <GridItem
                                    display={{
                                        base: 'none',
                                        md: 'block',
                                    }}
                                    // bg="whiteAlpha.50"
                                    borderRadius="lg"
                                    borderWidth={2}
                                    borderColor="whiteAlpha.100"
                                    borderStyle="dashed"
                                    p={6}
                                    cursor="pointer"
                                    userSelect="none"
                                    _hover={{ bg: 'whiteAlpha.50' }}
                                >
                                    <VStack w="full" h="full" justify="center">
                                        <HStack
                                            color="whiteAlpha.500"
                                            fontWeight="medium"
                                        >
                                            <Icon fontSize="lg" as={AiOutlinePlus}></Icon>
                                            <Text>Add topic</Text>
                                        </HStack>
                                    </VStack>
                                </GridItem>
                            )}
                        </Grid>
                        <VStack
                            align="start"
                            w="full"
                            spacing={0}
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.200"
                                />
                            }
                        >
                            <HStack py={3} w="full" justify="space-between">
                                <HStack fontWeight="semibold" color="whiteAlpha.500">
                                    <Text>
                                        {props.source.items.length}{' '}
                                        {pluralize('source', props.source.items.length)}
                                    </Text>
                                    <Tooltip
                                        placement="top"
                                        px={2}
                                        py={1}
                                        bg="gray.50"
                                        color="gray.900"
                                        shouldWrapChildren={true}
                                        label="Nisi laboris nulla in adipisicing adipisicing"
                                    >
                                        <Icon as={AiOutlineInfoCircle} />
                                    </Tooltip>
                                </HStack>
                                <Text
                                    color="blue.300"
                                    fontWeight="medium"
                                    _hover={{ textDecor: 'underline' }}
                                    cursor="pointer"
                                >
                                    View all
                                </Text>
                            </HStack>
                            <VStack align="start" w="full" spacing={0}>
                                {props.source.items.map(
                                    ({ source, ...item }, index, array) => (
                                        <VStack
                                            align="start"
                                            w="full"
                                            spacing={1}
                                            py={6}
                                            borderBottomWidth={
                                                array.length - 1 === index ? undefined : 2
                                            }
                                            borderStyle="dashed"
                                            borderColor="whiteAlpha.200"
                                        >
                                            <HStack w="full" spacing={3}>
                                                <Text
                                                    color="whiteAlpha.800"
                                                    fontWeight="semibold"
                                                    noOfLines={1}
                                                    cursor="pointer"
                                                    _hover={{ textDecor: 'underline' }}
                                                >
                                                    {source.label}
                                                </Text>
                                                <HStack>
                                                    <Text
                                                        fontSize="sm"
                                                        fontWeight="medium"
                                                        color="whiteAlpha.500"
                                                    >
                                                        {item.references.label}
                                                    </Text>
                                                </HStack>
                                            </HStack>
                                            <Text color="whiteAlpha.600" noOfLines={1}>
                                                {source.description}
                                            </Text>
                                        </VStack>
                                    )
                                )}
                            </VStack>
                        </VStack>
                    </VStack>
                </LayoutBody>
            </LayoutContent>
        </LayoutRoot>
    );
}
