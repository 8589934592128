import React from 'react';
import { ReportingContainerBaseConfig } from '../../../base';
import { InstanceReportDetailParameterSchema } from './reportDetailSchema';

import {
    ReportInstanceDetailController,
    ReportInstanceDetailLoader,
} from './reportDetailInterface';
import {
    ReportInstanceDetailContainerProps,
    ReportInstanceDetailViewProps,
} from './reportDetailProps';
import { ReportChapterItemProps } from '../../../view/chapter';

export function createReportInstanceDetailContainer(
    config: ReportingContainerBaseConfig,
    loader: ReportInstanceDetailLoader,
    controller: ReportInstanceDetailController,
    View: {
        Overview: React.FC<ReportInstanceDetailViewProps>;
        Chapter: React.FC<ReportChapterItemProps>;
    }
): React.FC<ReportInstanceDetailContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
        router: {
            report: {
                Component: { Root, Route },
            },
        },
    } = config;

    return ({ ...containerProps }) => {
        const options = useOptions(InstanceReportDetailParameterSchema);
        const context = useContext();
        const data = loader.useLoad(context, options);
        const props = controller.useProps(context, data, {});

        return (
            <Root>
                <Route index={true} element={<View.Overview {...props} />} />
                {props.item.chapters.map((chapter) => (
                    <Route
                        key={chapter.id}
                        path={chapter.id}
                        element={<View.Chapter {...chapter} />}
                    />
                ))}
            </Root>
        );
    };
}
