import { ReportingLoaderBaseConfig } from '../../../base';
import { ReportInstanceDetailLoader } from './reportDetailInterface';
import * as fixture from './reportDetailFixture';

export function createReportInstanceDetailLoader(
    config: ReportingLoaderBaseConfig
): ReportInstanceDetailLoader {
    return {
        useLoad(context, props) {
            return {
                report: fixture.report,
                author: fixture.author,
                content: fixture.content,
            };
        },
    };
}
