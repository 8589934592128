import { ReportingControllerBaseConfig } from '../../../base';
import { ReportOverviewController } from './reportOverviewInterface';
import { ReportOverviewViewProps } from './reportOverviewProps';

export function createReportOverviewController(
    config: ReportingControllerBaseConfig
): ReportOverviewController {
    return {
        useProps(context, item, props): ReportOverviewViewProps {
            return {};
        },
    };
}
