import React from 'react';
import { AiOutlineEdit, AiOutlineHistory, AiOutlineInfoCircle } from 'react-icons/ai';
import { BsArrowLeftShort } from 'react-icons/bs';
import {
    Box,
    Button,
    ButtonGroup,
    ButtonProps,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Progress,
    SimpleGrid,
    StackDivider,
    StackProps,
    Tag,
    Text,
    TextProps,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ReportingViewBaseConfig } from '../../../../base';
import { ReportChapterItemProps } from '../../../../view/chapter';
import { SourceListProps } from '../../../../view';
import { BiCommentDetail } from 'react-icons/bi';
import { FiShare } from 'react-icons/fi';

export function createReportChapterView(
    config: ReportingViewBaseConfig
): React.FC<ReportChapterItemProps> {
    const {
        UI: {
            Application: { Anchor },
        },
        Layout: {
            Centered: {
                Component: {
                    Root: LayoutRoot,
                    Header: LayoutHeader,
                    Content: LayoutContent,
                    Body: LayoutBody,
                },
            },
        },
        router: {
            report: {
                Component: { Link },
                useNavigate,
            },
        },
    } = config;

    const colors = {
        breadcrumb: 'blue.300',
        breadcrumbHover: 'blue.400',
        caption: 'whiteAlpha.500',
        chevron: 'whiteAlpha.500',
    } satisfies Record<string, TextProps['color']>;

    const colorSchemes = {
        tag: 'blue',
    } satisfies Record<string, ButtonProps['colorScheme']>;

    const SourceRankTag: React.FC<React.PropsWithChildren & StackProps> = (props) => {
        return (
            <HStack
                flexShrink={1}
                textTransform="uppercase"
                letterSpacing="wider"
                fontWeight="bold"
                fontSize="sm"
                userSelect="none"
                bg="green.200"
                color="black"
                borderRadius="full"
                minH={0}
                lineHeight={1}
                py={0}
                px={3}
                textAlign="center"
                {...props}
            >
                <Text>{props.children}</Text>
            </HStack>
        );
    };

    const RankingPopover: React.FC = () => {
        return (
            <VStack
                align="start"
                w="full"
                divider={<StackDivider borderWidth={0.5} borderColor="whiteAlpha.200" />}
                spacing={0}
            >
                <HStack px={4} py={2} justify="space-between" w="full">
                    <Text fontWeight="semibold" color="whiteAlpha.900">
                        SourceRank
                    </Text>
                    <HStack
                        fontSize="sm"
                        letterSpacing="wider"
                        color="whiteAlpha.600"
                        spacing={1}
                        fontWeight="medium"
                    >
                        <Text color="green.200" fontWeight="bold">
                            9.3
                        </Text>
                        <Text>/</Text>
                        <Text>10</Text>
                    </HStack>
                </HStack>
                <VStack
                    align="start"
                    w="full"
                    divider={
                        <StackDivider borderWidth={0.5} borderColor="whiteAlpha.200" />
                    }
                    spacing={0}
                    fontSize="sm"
                >
                    <VStack align="start" w="full" spacing={4} p={4}>
                        <HStack>
                            <Text fontWeight="medium" color="whiteAlpha.900">
                                Laboris duis est aliquip elit enim sunt cillum ea culpa
                            </Text>
                        </HStack>
                        <VStack align="start" w="full" spacing={2}>
                            <VStack align="start" w="full" spacing={0}>
                                <Text
                                    color="whiteAlpha.500"
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    fontSize="xs"
                                    letterSpacing="wide"
                                >
                                    Trust
                                </Text>
                                <Text color="whiteAlpha.800" fontWeight="semibold">
                                    Highly trustworthy
                                </Text>
                            </VStack>
                            <Progress colorScheme="green" size="sm" value={95} w="full" />
                        </VStack>
                        <VStack align="start" w="full" spacing={2}>
                            <VStack align="start" w="full" spacing={0}>
                                <Text
                                    color="whiteAlpha.500"
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    fontSize="xs"
                                    letterSpacing="wide"
                                >
                                    Relevance
                                </Text>
                                <Text color="whiteAlpha.800" fontWeight="semibold">
                                    Very relevant
                                </Text>
                            </VStack>
                            <Progress colorScheme="green" size="sm" value={89} w="full" />
                        </VStack>
                        <VStack align="start" w="full" spacing={2}>
                            <VStack align="start" w="full" spacing={0}>
                                <Text
                                    color="whiteAlpha.500"
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    fontSize="xs"
                                    letterSpacing="wide"
                                >
                                    Intent
                                </Text>
                                <Text color="whiteAlpha.800" fontWeight="semibold">
                                    No conflict of interest
                                </Text>
                            </VStack>
                            <Progress colorScheme="green" size="sm" value={84} w="full" />
                        </VStack>
                    </VStack>
                    <HStack w="full" justify="start" p={4}>
                        <Text
                            fontWeight="medium"
                            color="blue.300"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline' }}
                        >
                            Learn more about SourceRank
                        </Text>
                    </HStack>
                </VStack>
            </VStack>
        );
    };

    const SourceList: React.FC<SourceListProps> = (props) => {
        return (
            <VStack
                align="start"
                w="full"
                divider={<StackDivider borderWidth={0.5} borderColor="whiteAlpha.200" />}
                spacing={0}
            >
                <HStack px={4} py={2}>
                    <Text fontWeight="semibold" color="whiteAlpha.900">
                        Sources
                    </Text>
                </HStack>
                <VStack
                    align="start"
                    w="full"
                    divider={
                        <StackDivider borderWidth={0.5} borderColor="whiteAlpha.200" />
                    }
                    spacing={0}
                    fontSize="sm"
                >
                    {props.items.map((item) => (
                        <VStack key={item.id} align="start" w="full" p={4} spacing={1}>
                            <HStack w="full" justify="space-between" spacing={6}>
                                <Text
                                    noOfLines={1}
                                    fontWeight="semibold"
                                    color="whiteAlpha.800"
                                    cursor="pointer"
                                    _hover={{ textDecoration: 'underline' }}
                                >
                                    {item.label}
                                </Text>
                                <SourceRankTag h={6}>9.1</SourceRankTag>
                            </HStack>
                            <Text color="whiteAlpha.600" noOfLines={2}>
                                {item.description}
                            </Text>
                            {item.anchor && (
                                <Anchor
                                    {...item.anchor}
                                    color="blue.300"
                                    target="_blank"
                                    fontWeight="medium"
                                >
                                    <Text noOfLines={1}>View source</Text>
                                </Anchor>
                            )}
                        </VStack>
                    ))}
                </VStack>
            </VStack>
        );
    };

    return (props) => {
        return (
            <LayoutRoot>
                <LayoutContent>
                    <LayoutBody>
                        <VStack align="start" w="full" spacing={12}>
                            <VStack w="full" align="start" spacing={8}>
                                <VStack w="full" align="start" spacing={8}>
                                    <HStack w="full" justify="space-between">
                                        <HStack
                                            spacing={2}
                                            fontSize="md"
                                            fontWeight="medium"
                                            divider={
                                                <Icon
                                                    border="none"
                                                    color="whiteAlpha.400"
                                                    fontSize="xl"
                                                    as={ChevronRightIcon}
                                                />
                                            }
                                        >
                                            <Link to={null}>
                                                <HStack spacing={4}>
                                                    <IconButton
                                                        aria-label="back"
                                                        borderRadius="full"
                                                        borderWidth={1}
                                                        borderColor="whiteAlpha.300"
                                                        bg="none"
                                                        _hover={{
                                                            bg: 'whiteAlpha.50',
                                                        }}
                                                        icon={
                                                            <Icon
                                                                fontSize="2xl"
                                                                color="whiteAlpha.800"
                                                                as={BsArrowLeftShort}
                                                            />
                                                        }
                                                    />
                                                    <Text
                                                        color={colors.breadcrumb}
                                                        _hover={{
                                                            color: colors.breadcrumbHover,
                                                        }}
                                                    >
                                                        {props.parent.title}
                                                    </Text>
                                                </HStack>
                                            </Link>
                                        </HStack>
                                        <HStack spacing={3}>
                                            <HStack spacing={1}>
                                                <Tooltip
                                                    placement="bottom"
                                                    openDelay={300}
                                                    px={2}
                                                    py={1}
                                                    bg="gray.50"
                                                    color="gray.900"
                                                    shouldWrapChildren={true}
                                                    label={`Last edited 3 hours ago by ${props.author.label}`}
                                                >
                                                    <IconButton
                                                        aria-label="back"
                                                        // borderRadius="full"
                                                        // borderWidth={1}
                                                        // borderColor="whiteAlpha.300"
                                                        bg="none"
                                                        _hover={{
                                                            bg: 'whiteAlpha.50',
                                                        }}
                                                        icon={
                                                            <Icon
                                                                fontSize="lg"
                                                                color="whiteAlpha.800"
                                                                as={AiOutlineHistory}
                                                            />
                                                        }
                                                    />
                                                </Tooltip>
                                                <Tooltip
                                                    placement="bottom"
                                                    openDelay={300}
                                                    px={2}
                                                    py={1}
                                                    bg="gray.50"
                                                    color="gray.900"
                                                    shouldWrapChildren={true}
                                                    label="Show all comments"
                                                >
                                                    <IconButton
                                                        aria-label="back"
                                                        // borderRadius="full"
                                                        // borderWidth={1}
                                                        // borderColor="whiteAlpha.300"
                                                        bg="none"
                                                        _hover={{
                                                            bg: 'whiteAlpha.50',
                                                        }}
                                                        icon={
                                                            <Icon
                                                                fontSize="lg"
                                                                color="whiteAlpha.800"
                                                                as={BiCommentDetail}
                                                            />
                                                        }
                                                    />
                                                </Tooltip>
                                            </HStack>
                                            <ButtonGroup
                                                isAttached={true}
                                                borderWidth={1}
                                                borderColor="whiteAlpha.300"
                                                variant="outline"
                                                borderRadius="full"
                                                overflow="hidden"
                                            >
                                                <Button
                                                    color="whiteAlpha.800"
                                                    bg="none"
                                                    _hover={{
                                                        bg: 'whiteAlpha.50',
                                                    }}
                                                    borderRightWidth={1}
                                                    borderRightColor="whiteAlpha.300"
                                                >
                                                    Share
                                                </Button>
                                                <IconButton
                                                    aria-label="more"
                                                    bg="none"
                                                    _hover={{
                                                        bg: 'whiteAlpha.50',
                                                    }}
                                                    icon={
                                                        <Icon
                                                            fontSize="lg"
                                                            as={ChevronDownIcon}
                                                        />
                                                    }
                                                />
                                            </ButtonGroup>
                                        </HStack>
                                    </HStack>
                                    <VStack align="start" w="full">
                                        <VStack align="start" spacing={1}>
                                            <Text
                                                textTransform="uppercase"
                                                fontWeight="bold"
                                                fontSize="sm"
                                                letterSpacing="wide"
                                                color={colors.caption}
                                            >
                                                {props.caption}
                                            </Text>
                                            <Heading
                                                color="whiteAlpha.900"
                                                fontSize="2xl"
                                                fontWeight="semibold"
                                            >
                                                {props.title}
                                            </Heading>
                                        </VStack>
                                        <HStack
                                            flexShrink={0}
                                            spacing={1}
                                            color="whiteAlpha.500"
                                            userSelect="none"
                                        >
                                            <Text>Last edited</Text>
                                            <Text
                                                fontWeight="semibold"
                                                _hover={{ textDecor: 'underline' }}
                                            >
                                                3 hours ago
                                            </Text>
                                            <Text>by</Text>
                                            <Text
                                                fontWeight="semibold"
                                                _hover={{ textDecor: 'underline' }}
                                            >
                                                {props.author.label}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </VStack>
                                <VStack align="start" w="full" spacing={12}>
                                    <SimpleGrid
                                        columns={2}
                                        gap={6}
                                        w="full"
                                        templateColumns="min-content 1fr"
                                    >
                                        <Box maxH={16} py={1} h="full">
                                            <Box
                                                bg="whiteAlpha.200"
                                                w={1.5}
                                                h="full"
                                                borderRadius="full"
                                            />
                                        </Box>
                                        <Text fontSize="lg" color="whiteAlpha.700">
                                            {props.parent.summary}
                                        </Text>
                                    </SimpleGrid>
                                    {props.hightlights && (
                                        <Grid
                                            templateColumns={{
                                                base: 'repeat(1, minmax(0, 1fr))',
                                                md: 'repeat(2, minmax(0, 1fr))',
                                            }}
                                            gap={4}
                                            w="full"
                                        >
                                            {props.hightlights.map((item, index) => (
                                                <GridItem key={item.id}>
                                                    <HStack
                                                        spacing={8}
                                                        justify="space-between"
                                                        w="full"
                                                        h="full"
                                                        py={4}
                                                        px={5}
                                                        cursor="pointer"
                                                        borderRadius="sm"
                                                        color="whiteAlpha.600"
                                                        bg="whiteAlpha.50"
                                                        _hover={{
                                                            bg: 'whiteAlpha.100',
                                                        }}
                                                        maxW="full"
                                                        fontWeight="medium"
                                                    >
                                                        <Text noOfLines={1}>
                                                            {item.label}
                                                        </Text>
                                                        <Icon
                                                            color={colors.chevron}
                                                            fontSize="2xl"
                                                            as={ChevronRightIcon}
                                                        />
                                                    </HStack>
                                                </GridItem>
                                            ))}
                                        </Grid>
                                    )}
                                </VStack>
                            </VStack>
                            <VStack align="start" w="full" spacing={8}>
                                {props.sections.map((item, index, array) => (
                                    <VStack
                                        key={item.id}
                                        align="start"
                                        w="full"
                                        spacing={4}
                                        // NOTE stack divider does not support proper dashed styling
                                        {...(index === array.length - 1
                                            ? {}
                                            : {
                                                  pb: 8,
                                                  borderBottomWidth: 3,
                                                  borderStyle: 'dashed',
                                                  borderColor: 'whiteAlpha.200',
                                              })}
                                    >
                                        <HStack
                                            w="full"
                                            justify="space-between"
                                            align="start"
                                        >
                                            <VStack align="start" spacing={1}>
                                                <Text
                                                    fontWeight="bold"
                                                    fontSize="sm"
                                                    textTransform="uppercase"
                                                    letterSpacing="wider"
                                                    color={colors.caption}
                                                >
                                                    {item.caption}
                                                </Text>
                                                {item.title && (
                                                    <Heading
                                                        fontWeight="medium"
                                                        fontSize="xl"
                                                        color="whiteAlpha.800"
                                                    >
                                                        {item.title}
                                                    </Heading>
                                                )}
                                            </VStack>
                                            <Popover
                                                placement="top-end"
                                                trigger="hover"
                                                openDelay={300}
                                            >
                                                <PopoverTrigger>
                                                    <Box>
                                                        <SourceRankTag h={7}>
                                                            9.3
                                                        </SourceRankTag>
                                                    </Box>
                                                </PopoverTrigger>
                                                <PopoverContent
                                                    overflow="hidden"
                                                    bg="black"
                                                    _focusVisible={{
                                                        outline: 'none',
                                                    }}
                                                >
                                                    <PopoverBody
                                                        p={0}
                                                        bg="whiteAlpha.200"
                                                    >
                                                        <RankingPopover />
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        </HStack>
                                        <VStack
                                            align="start"
                                            spacing={3}
                                            color="whiteAlpha.600"
                                        >
                                            {item.content.map((content, index) => (
                                                <HStack key={index}>
                                                    <Text>{content.text}</Text>
                                                </HStack>
                                            ))}
                                        </VStack>
                                        <HStack
                                            w="full"
                                            justify="space-between"
                                            align="start"
                                        >
                                            <HStack
                                                w="full"
                                                spacing={2}
                                                userSelect="none"
                                            >
                                                {item.keywords.map((keyword) => (
                                                    <Tag
                                                        key={keyword.id}
                                                        colorScheme={colorSchemes.tag}
                                                        // color="whiteAlpha.800"
                                                        size="lg"
                                                        borderRadius="sm"
                                                        fontWeight="medium"
                                                    >
                                                        {keyword.label}
                                                    </Tag>
                                                ))}
                                            </HStack>
                                            <Popover
                                                placement="top-end"
                                                trigger="hover"
                                                openDelay={300}
                                            >
                                                <PopoverTrigger>
                                                    <HStack
                                                        spacing={2}
                                                        fontWeight="medium"
                                                        justify="end"
                                                        w="full"
                                                        fontStyle="italic"
                                                        fontSize="sm"
                                                        userSelect="none"
                                                        whiteSpace="nowrap"
                                                        color="whiteAlpha.400"
                                                    >
                                                        <Text>
                                                            Source: {item.source.label}
                                                        </Text>
                                                        <Icon as={AiOutlineInfoCircle} />
                                                    </HStack>
                                                </PopoverTrigger>
                                                <PopoverContent
                                                    overflow="hidden"
                                                    bg="black"
                                                    _focusVisible={{ outline: 'none' }}
                                                    w="full"
                                                    maxW="24rem"
                                                >
                                                    <PopoverBody
                                                        p={0}
                                                        bg="whiteAlpha.200"
                                                    >
                                                        <SourceList {...item.source} />
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        </HStack>
                                    </VStack>
                                ))}
                            </VStack>
                        </VStack>
                    </LayoutBody>
                </LayoutContent>
            </LayoutRoot>
        );
    };
}
