import {
    AiOutlineCalendar,
    AiOutlineClose,
    AiOutlineEdit,
    AiOutlinePlus,
    AiOutlineReload,
    AiOutlineWarning,
} from 'react-icons/ai';
import {
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Center,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    StackDivider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    Text,
    Textarea,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { BsArrowRight, BsSortDown } from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';
import { Icons } from '../../../../../../config';
import { ReportingViewBaseConfig } from '../../../base';
import { ReportOverviewViewProps } from './reportOverviewProps';
import {
    MdFormatListBulleted,
    MdOutlineGridView,
    MdOutlineLibraryBooks,
} from 'react-icons/md';
import { TbReportSearch } from 'react-icons/tb';

export function createReportOverviewView(
    config: ReportingViewBaseConfig
): React.FC<ReportOverviewViewProps> {
    const {
        Layout: {
            Centered: {
                Component: {
                    Root: LayoutRoot,
                    Header: LayoutHeader,
                    Content: LayoutContent,
                    Body: LayoutBody,
                },
            },
        },
        UI: {
            Application: { Link },
            Date: {
                DatePicker: {
                    Container: DateContainer,
                    Absolute: DateAbsolute,
                    Relative: DateRelative,
                },
            },
        },
    } = config;

    const containerStyle = {
        maxW: { base: '90vw', lg: '85vw', xl: '75vw', '2xl': '72rem' },
    } satisfies Partial<BoxProps>;

    const SectionList: React.FC<React.PropsWithChildren> = (props) => {
        return (
            <VStack
                w="full"
                spacing={8}
                align="start"
                divider={<StackDivider borderColor="whiteAlpha.100" borderWidth={1} />}
            >
                {props.children}
            </VStack>
        );
    };

    const Section: React.FC<
        React.PropsWithChildren & { title: React.ReactNode; description: React.ReactNode }
    > = (props) => {
        return (
            <VStack align="start" w="full" spacing={4}>
                <VStack align="start" w="full" spacing={4}>
                    <Heading fontSize="lg" fontWeight="semibold" color="whiteAlpha.900">
                        {props.title}
                    </Heading>
                    <Text color="whiteAlpha.700">{props.description}</Text>
                </VStack>
                {props.children}
            </VStack>
        );
    };

    const reports = [
        {
            id: '1',
            title: `Automating KYC with AI`,
            author: {
                name: 'John Doe',
                avatar: 'https://bit.ly/broken-link',
            },
            lastUpdated: {
                relative: '2 days ago',
            },
            createdAt: {
                relative: '5 days ago',
            },
        },
        {
            id: '2',
            title: `Developer maintenance automation`,
            author: {
                name: 'John Doe',
                avatar: 'https://bit.ly/broken-link',
            },
            lastUpdated: {
                relative: '3 days ago',
            },
            createdAt: {
                relative: '6 days ago',
            },
        },
    ];

    return (props) => {
        return (
            <LayoutRoot>
                <LayoutContent>
                    <LayoutHeader>
                        <HStack w="full" justify="space-between" align="start">
                            <HStack w="full">
                                <VStack align="start" spacing={2}>
                                    <VStack align="start" spacing={1}>
                                        <Heading fontSize="2xl" fontWeight="semibold">
                                            Reports
                                        </Heading>
                                    </VStack>
                                </VStack>
                            </HStack>
                            <HStack>
                                <Link to="../home">
                                    <Button
                                        borderRadius="full"
                                        colorScheme="blue"
                                        variant="outline"
                                        // bg="none"
                                        // borderWidth={1}
                                        // borderColor="whiteAlpha.800"
                                        // color="whiteAlpha.800"
                                        // _hover={{ bg: 'whiteAlpha.50' }}
                                        leftIcon={<Icon as={AiOutlinePlus} />}
                                    >
                                        New report
                                    </Button>
                                </Link>
                            </HStack>
                        </HStack>
                    </LayoutHeader>
                    <LayoutBody>
                        <VStack
                            align="start"
                            w="full"
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.200"
                                />
                            }
                            spacing={4}
                        >
                            <HStack w="full" justify="space-between" align="end">
                                <Text
                                    fontSize="sm"
                                    fontWeight="semibold"
                                    color="whiteAlpha.600"
                                >
                                    3 results
                                </Text>
                                <HStack>
                                    <Button
                                        size="sm"
                                        leftIcon={<Icon fontSize="lg" as={BsSortDown} />}
                                        bg="none"
                                        _hover={{ bg: 'whiteAlpha.50' }}
                                    >
                                        Most viewed
                                    </Button>
                                    <ButtonGroup
                                        isAttached={true}
                                        borderWidth={1}
                                        borderColor="whiteAlpha.200"
                                        borderRadius="md"
                                        overflow="hidden"
                                        size="sm"
                                        userSelect="none"
                                    >
                                        <IconButton
                                            aria-selected={false}
                                            aria-label="grid"
                                            bg="none"
                                            _hover={{ bg: 'whiteAlpha.50' }}
                                            color="whiteAlpha.600"
                                            _selected={{
                                                color: 'whiteAlpha.800',
                                                bg: 'whiteAlpha.100',
                                            }}
                                            icon={
                                                <Icon
                                                    fontSize="lg"
                                                    as={MdOutlineGridView}
                                                />
                                            }
                                            borderRadius="none"
                                        />
                                        <IconButton
                                            aria-selected={true}
                                            bg="none"
                                            _hover={{ bg: 'whiteAlpha.50' }}
                                            color="whiteAlpha.600"
                                            _selected={{
                                                color: 'whiteAlpha.800',
                                                bg: 'whiteAlpha.100',
                                            }}
                                            aria-label="list"
                                            icon={
                                                <Icon
                                                    fontSize="lg"
                                                    as={MdFormatListBulleted}
                                                />
                                            }
                                            borderRadius="none"
                                        />
                                    </ButtonGroup>
                                </HStack>
                            </HStack>
                            <VStack align="start" w="full">
                                {reports.map((item) => (
                                    <Link
                                        style={{ width: '100%' }}
                                        key={item.id}
                                        to={item.id}
                                    >
                                        <HStack
                                            w="full"
                                            justify="space-between"
                                            p={4}
                                            borderRadius="md"
                                            spacing={4}
                                            cursor="pointer"
                                            _hover={{ bg: 'whiteAlpha.50' }}
                                        >
                                            <HStack w="full" spacing={4}>
                                                <HStack
                                                    fontSize="md"
                                                    fontWeight="medium"
                                                    color="whiteAlpha.900"
                                                    flexBasis="24rem"
                                                    spacing={3}
                                                >
                                                    <Box
                                                    // p={2.5}
                                                    // bg="whiteAlpha.50"
                                                    // borderRadius="sm"
                                                    >
                                                        <Icon
                                                            // color="purple.200"
                                                            fontSize="xl"
                                                            as={MdOutlineLibraryBooks}
                                                        />
                                                    </Box>
                                                    <Text noOfLines={1}>
                                                        {item.title}
                                                    </Text>
                                                </HStack>
                                                <HStack
                                                    fontSize="sm"
                                                    color="whiteAlpha.600"
                                                >
                                                    <Text noOfLines={1}>
                                                        Last edited{' '}
                                                        {item.createdAt.relative}
                                                    </Text>
                                                </HStack>
                                            </HStack>
                                            <HStack flexShrink={0}>
                                                <Tag size="lg" colorScheme="orange">
                                                    Generating
                                                </Tag>
                                            </HStack>
                                        </HStack>
                                    </Link>
                                ))}
                            </VStack>
                        </VStack>
                    </LayoutBody>
                </LayoutContent>
            </LayoutRoot>
        );
    };
}
