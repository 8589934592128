import {
    AiOutlineCalendar,
    AiOutlineClose,
    AiOutlineEdit,
    AiOutlinePlus,
    AiOutlineReload,
    AiOutlineWarning,
} from 'react-icons/ai';
import TextareaAutosize from 'react-textarea-autosize';
import {
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Center,
    chakra,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    SimpleGrid,
    StackDivider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    Text,
    Textarea,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { BsArrowRight, BsArrowRightShort } from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';
import { Graphics } from '../../../../config/svg';
import { ReportingViewBaseConfig } from '../../base';
import { ReportGenerateViewProps } from './reportHomeProps';
import { RiAttachment2 } from 'react-icons/ri';
import { MdOutlineAlternateEmail } from 'react-icons/md';

export function createReportGenerateView(
    config: ReportingViewBaseConfig
): React.FC<ReportGenerateViewProps> {
    const {
        Layout: {
            Centered: {
                Component: {
                    Root: LayoutRoot,
                    Header: LayoutHeader,
                    Content: LayoutContent,
                    Body: LayoutBody,
                },
            },
        },
        UI: {
            Application: { Link },
            Date: {
                DatePicker: {
                    Container: DateContainer,
                    Absolute: DateAbsolute,
                    Relative: DateRelative,
                },
            },
        },
    } = config;

    const containerStyle = {
        maxW: { base: '90vw', lg: '85vw', xl: '75vw', '2xl': '72rem' },
    } satisfies Partial<BoxProps>;

    const recommendations = [
        {
            id: '1',
            title: `I want to automate KYC with AI`,
        },
        {
            id: '2',
            title: `I want to automate customer support with AI`,
        },
        {
            id: '3',
            title: `I want to automate customer onboarding with AI`,
        },
        {
            id: '4',
            title: `I want to automate developer maintenance with AI`,
        },
    ];

    const ChakraTextareaAutosize = chakra(TextareaAutosize, {
        baseStyle: {
            // any default Chakra styles you want here
            // e.g. fontSize: "md", etc.
        },
    });

    return (props) => {
        return (
            <LayoutRoot>
                <LayoutContent>
                    <LayoutBody>
                        <VStack
                            align="start"
                            w="full"
                            spacing={8}
                            // py={{ base: 4, md: 8, lg: 8 }}
                            py={4}
                        >
                            <VStack align="start" spacing={8} w="full">
                                <HStack
                                    borderRadius="full"
                                    p={4}
                                    borderWidth={2}
                                    borderColor="whiteAlpha.200"
                                    justify="center"
                                    align="center"
                                >
                                    <Image
                                        pos="relative"
                                        left={-0.5}
                                        top={0.5}
                                        w={6}
                                        h={6}
                                        src={Graphics.Brand.Icon}
                                    />
                                </HStack>
                                <VStack align="start" spacing={2}>
                                    <Heading
                                        fontWeight="semibold"
                                        fontSize="2xl"
                                        color="whiteAlpha.900"
                                    >
                                        Get started
                                    </Heading>
                                    <Text
                                        color="whiteAlpha.700"
                                        fontSize="lg"
                                        maxW="32rem"
                                    >
                                        Ask a question or describe a task you want to
                                        accomplish
                                    </Text>
                                </VStack>
                            </VStack>
                            <VStack w="full" spacing={4}>
                                <SimpleGrid w="full" gap={2} columns={{ base: 1, md: 2 }}>
                                    {recommendations.map((item) => (
                                        <Link to="/reporting/reports/new" key={item.id}>
                                            <VStack
                                                align="start"
                                                w="full"
                                                p={4}
                                                borderRadius="lg"
                                                cursor="pointer"
                                                color="whiteAlpha.600"
                                                // bg="whiteAlpha.50"
                                                _hover={{ bg: 'whiteAlpha.50' }}
                                                borderWidth={1}
                                                borderColor="whiteAlpha.200"
                                            >
                                                <Text fontWeight="semibold" noOfLines={1}>
                                                    {item.title}
                                                </Text>
                                            </VStack>
                                        </Link>
                                    ))}
                                </SimpleGrid>
                                <Box
                                    w="full"
                                    as="form"
                                    {...props.form.getFormElementProps()}
                                >
                                    <VStack
                                        w="full"
                                        align="start"
                                        spacing={4}
                                        p={4}
                                        borderWidth={1}
                                        borderColor="whiteAlpha.200"
                                        borderRadius={20}
                                        position="relative"
                                    >
                                        <ChakraTextareaAutosize
                                            {...props.field.question.input}
                                            w="full"
                                            lineHeight={1.25}
                                            autoFocus={true}
                                            minRows={1}
                                            maxRows={8}
                                            border="none"
                                            bg="none"
                                            tabIndex={1}
                                            resize="none"
                                            placeholder="Write something"
                                            _focus={{
                                                outline: 'none',
                                            }}
                                            _focusVisible={{
                                                outline: 'none',
                                            }}
                                        />
                                        <HStack
                                            w="full"
                                            justify="space-between"
                                            align="end"
                                        >
                                            <HStack spacing={1}>
                                                <IconButton
                                                    bg="none"
                                                    _hover={{ bg: 'whiteAlpha.50' }}
                                                    color="whiteAlpha.600"
                                                    aria-label="file"
                                                    size="sm"
                                                    icon={
                                                        <Icon
                                                            fontSize="lg"
                                                            as={RiAttachment2}
                                                        />
                                                    }
                                                />
                                                <IconButton
                                                    bg="none"
                                                    _hover={{ bg: 'whiteAlpha.50' }}
                                                    color="whiteAlpha.600"
                                                    aria-label="at"
                                                    size="sm"
                                                    icon={
                                                        <Icon
                                                            fontSize="lg"
                                                            as={MdOutlineAlternateEmail}
                                                        />
                                                    }
                                                />
                                            </HStack>
                                            {/* <Button
                                                {...props.submit.button}
                                                size="md"
                                                borderRadius="full"
                                                bg="white"
                                                color="black"
                                                _hover={{
                                                    bg: 'whiteAlpha.900',
                                                }}
                                                _disabled={{ opacity: 0.5 }}
                                            >
                                                Continue
                                            </Button> */}
                                            <IconButton
                                                {...props.submit.button}
                                                aria-label="continue"
                                                icon={
                                                    <Icon
                                                        fontSize="3xl"
                                                        as={BsArrowRightShort}
                                                    />
                                                }
                                                size="md"
                                                borderRadius="full"
                                                bg="white"
                                                color="black"
                                                _hover={{
                                                    bg: 'whiteAlpha.900',
                                                }}
                                                _disabled={{ opacity: 0.3 }}
                                            />
                                        </HStack>
                                    </VStack>
                                </Box>
                            </VStack>
                        </VStack>
                    </LayoutBody>
                </LayoutContent>
            </LayoutRoot>
        );
    };
}
