import pluralize from 'pluralize';
import { ReportingControllerBaseConfig } from '../../../base';
import { ReportChapterItemProps } from '../../../view/chapter';
import { ReportInstanceDetailController } from './reportDetailInterface';
import {
    ReportInstanceDetailViewProps,
    ReportSourceItemProps,
} from './reportDetailProps';
import { AiOutlineClockCircle, AiOutlineRobot, AiOutlineUser } from 'react-icons/ai';
import { SourceItemProps } from '../../../view';
import { chain } from 'lodash';

export function createReportInstanceDetailController(
    config: ReportingControllerBaseConfig
): ReportInstanceDetailController {
    return {
        useProps(context, item, props): ReportInstanceDetailViewProps {
            const counts = {
                totalSources: item.content.chapters.reduce(
                    (acc, chapter) => acc + chapter.sources.length,
                    0
                ),
            };
            return {
                source: {
                    items: chain(item.content.chapters)
                        .flatMap((chapter) =>
                            chapter.sections.flatMap((section) =>
                                section.sources.map((source): ReportSourceItemProps => {
                                    const referenceCount = chapter.sections.reduce(
                                        (acc, section) =>
                                            acc +
                                            section.sources.filter(
                                                (candidate) =>
                                                    candidate.name === source.name
                                            ).length,
                                        0
                                    );
                                    return {
                                        source: {
                                            id: source.id,
                                            label: source.name,
                                            description: source.description,
                                            anchor: source.url
                                                ? { id: '', href: source.url }
                                                : null,
                                        },
                                        references: {
                                            count: referenceCount,
                                            label: [
                                                referenceCount,
                                                pluralize('reference', referenceCount),
                                            ].join(' '),
                                        },
                                    };
                                })
                            )
                        )
                        .uniqBy((item) => item.source.label)
                        .orderBy((item) => item.references.count, 'desc')
                        .value(),
                },
                item: {
                    item: {
                        title: item.report.title,
                        keywords: [
                            {
                                id: '0',
                                label: item.author.name,
                                Icon: AiOutlineUser,
                            },
                            {
                                id: '1',
                                label: `Last updated today`,
                                Icon: null,
                            },
                            {
                                id: '2',
                                label: `${counts.totalSources} ${pluralize('source', counts.totalSources)}`,
                                Icon: null,
                            },
                        ],
                    },
                    summary: {
                        description: item.content.summary,
                    },
                    chapters: item.content.chapters.map(
                        (chapter, index): ReportChapterItemProps => {
                            const truncatedCount = chapter.sources.length - 1;
                            const sourceSuffix = pluralize(
                                'source',
                                chapter.sources.length
                            );
                            const chapterNumber = index + 1;

                            const [first] = chapter.sources;
                            const sourceFragments = [first.name];
                            if (truncatedCount > 0) {
                                sourceFragments.push(`${truncatedCount} more`);
                            }

                            return {
                                id: chapter.id,
                                number: chapterNumber,
                                parent: {
                                    title: item.report.title,
                                    summary: chapter.description,
                                },
                                hightlights: chapter.sections.map((section, index) => ({
                                    id: section.id,
                                    label: `${chapterNumber}.${index + 1}. ${section.caption}`,
                                })),
                                keywords: [
                                    {
                                        id: '0',
                                        label: item.author.name,
                                        Icon: AiOutlineUser,
                                    },
                                    {
                                        id: '1',
                                        label: `Updated today`,
                                        Icon: null,
                                    },
                                    {
                                        id: '2',
                                        label: `${chapter.sources.length} ${sourceSuffix}`,
                                        Icon: null,
                                    },
                                ],
                                author: {
                                    label: item.author.name,
                                    Icon: AiOutlineUser,
                                },
                                title: chapter.title,
                                description: chapter.description,
                                caption: `${chapterNumber}. ${chapter.caption}`,
                                link: {
                                    to: chapter.id,
                                },
                                source: {
                                    label: sourceFragments.join(' and '),
                                    items: chapter.sources.map((source) => ({
                                        id: source.id,
                                        label: source.name,
                                        description: source.description,
                                        anchor: source.url
                                            ? { id: '', href: source.url }
                                            : null,
                                    })),
                                    truncated:
                                        truncatedCount === 0
                                            ? null
                                            : {
                                                  count: truncatedCount,
                                                  label: `${truncatedCount} more`,
                                              },
                                },
                                sections: chapter.sections.map((section, index) => {
                                    const truncatedCount = section.sources.length - 1;
                                    const sectionNumber = index + 1;

                                    const [first] = section.sources;
                                    const sourceFragments = [first.name];
                                    if (truncatedCount > 0) {
                                        sourceFragments.push(`${truncatedCount} more`);
                                    }
                                    return {
                                        id: section.id,
                                        title: section.title,
                                        caption: `${chapterNumber}.${sectionNumber}. ${section.caption}`,
                                        content: section.content,
                                        keywords: section.keywords.map((keyword) => ({
                                            id: keyword.id,
                                            label: keyword.text,
                                        })),
                                        source: {
                                            label: sourceFragments.join(' and '),
                                            items: section.sources.map((source) => ({
                                                id: source.id,
                                                label: source.name,
                                                description: source.description,
                                                anchor: source.url
                                                    ? { id: '', href: source.url }
                                                    : null,
                                            })),
                                            truncated:
                                                truncatedCount === 0
                                                    ? null
                                                    : {
                                                          count: truncatedCount,
                                                          label: `${truncatedCount} more`,
                                                      },
                                        },
                                    };
                                }),
                            };
                        }
                    ),
                },
            };
        },
    };
}
