import { ReportingRouterConfig, ReportingRouterInit } from './reportingRouterConfig';
import { ReportingRouter, ReportingRouterEnhancer } from './reportingRouterInterface';
import {
    ReportingContainerBaseConfig,
    ReportingContextBaseConfig,
    ReportingControllerBaseConfig,
    ReportingLoaderBaseConfig,
    ReportingViewBaseConfig,
} from './base';
import {
    createReportChapterView,
    createReportDetailView,
    createReportGenerateContainer,
    createReportGenerateController,
    createReportGenerateLoader,
    createReportGenerateView,
    createReportInstanceDetailContainer,
    createReportInstanceDetailController,
    createReportInstanceDetailLoader,
    createReportNewContainer,
    createReportNewController,
    createReportNewLoader,
    createReportNewView,
    createReportOverviewContainer,
    createReportOverviewController,
    createReportOverviewLoader,
    createReportOverviewView,
} from './page';

export function createReportingRouter(
    config: ReportingRouterInit,
    enhancer?: ReportingRouterEnhancer
) {
    return create({ ...config }, enhancer);
}

function create(
    config: ReportingRouterConfig,
    enhancer?: ReportingRouterEnhancer
): ReportingRouter {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const contextConfig: ReportingContextBaseConfig = {};

    const Context = {};

    // Create all routers needed for the application
    const reportDetailRouter = config.kernel.provider.createRouter({
        state: {
            kind: 'hash',
        },
    });

    // Create a router map to be used throughout the app
    const router = {
        report: reportDetailRouter,
    };

    const containerConfig: ReportingContainerBaseConfig = {
        context: config.context,
        kernel: config.kernel,
        // repository: config.repository,
        UI: config.UI,
        router, // Pass the router map to containers
    };

    const loaderConfig: ReportingLoaderBaseConfig = {
        // repository: config.repository,
    };

    const controllerConfig: ReportingControllerBaseConfig = {
        navigation: {},
        kernel: config.kernel,
    };

    const viewConfig: ReportingViewBaseConfig = {
        UI: config.UI,
        Layout: config.Layout,
        router,
    };

    const ReportOverview = createReportOverviewContainer(
        containerConfig,
        createReportOverviewLoader(loaderConfig),
        createReportOverviewController(controllerConfig),
        createReportOverviewView(viewConfig)
    );

    const ReportDetail = createReportInstanceDetailContainer(
        containerConfig,
        createReportInstanceDetailLoader(loaderConfig),
        createReportInstanceDetailController(controllerConfig),
        {
            Overview: createReportDetailView(viewConfig),
            Chapter: createReportChapterView(viewConfig),
        }
    );

    const ReportGenerate = createReportGenerateContainer(
        containerConfig,
        createReportGenerateLoader(loaderConfig),
        createReportGenerateController(controllerConfig),
        createReportGenerateView(viewConfig)
    );

    const ReportNew = createReportNewContainer(
        containerConfig,
        createReportNewLoader(loaderConfig),
        createReportNewController(controllerConfig),
        createReportNewView(viewConfig)
    );

    return {
        Pages: {
            Report: {
                Overview: ReportOverview,
                Detail: ReportDetail,
                Generate: ReportGenerate,
                New: ReportNew,
            },
        },
        router,
    };
}
